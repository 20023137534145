.league-page-content {
  &.is-loading {
    @apply pointer-events-none opacity-60 blur-sm transition-all;
  }
}

.league-table {
  a {
    @apply underline transition-opacity hover:opacity-60;
  }

  @screen sm-down {
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
    }

    td {
      border-bottom: 1px solid #eceff1;
      display: block;
    }

    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
}
