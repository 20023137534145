[data-page-filters] {
  &.is-filtered {
    .page-filters-clear {
      @apply block;
    }
  }
}

.page-filter {
  @apply relative;
}

.page-filters-clear {
  @apply hidden;
}

[data-page-content].is-loading:not(.ignore-loading) {
  @apply relative blur-sm;
  transform: translateZ(1px);
}
