.site-footer-top {
  background: linear-gradient(155.07deg, #001f60 19.81%, #000f2f 84.13%);
  clip-path: url(#svg-path-footer);
}

.site-footer-bottom {
  @screen md {
    clip-path: url(#svg-path-footer-bottom);
  }
}

.pagetype-plananeventpage {
  .site-footer {
    @apply bg-gray-light;
  }
}
