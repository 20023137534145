.element-faqs {
  .faq-item[aria-expanded='false'] {
    .for-collapsed {
      @apply block;
    }

    .for-expanded {
      @apply hidden;
    }
  }

  .faq-item[aria-expanded='true'] {
    .for-collapsed {
      @apply hidden;
    }

    .for-expanded {
      @apply block;
    }
  }

  .is-active .faq-item {
    @apply lg:bg-blue lg:text-white;
  }

  [data-tab-panel][aria-hidden='true'] {
    @apply hidden;
  }

  [data-tab-trigger] {
    &:before {
      @apply absolute top-0 left-1/2 h-full bg-transparent;
      content: '';
      width: 105%;
    }
  }

  [role='tablist'] li {
    &:first-child [data-tab-trigger]:before {
      @apply -ml-2;
    }

    &:last-child [data-tab-trigger]:before {
      @apply ml-2;
    }
  }

  [data-tab-trigger][aria-selected='true'] {
    @apply pointer-events-none text-white;

    &:before {
      @apply bg-blue;
      transform: skewX(-20deg) translateX(-50%);
    }
  }

  .faq-answer-inner {
    @apply sticky;
    top: calc(var(--site-header-height) + 24px);
  }
}
