.field-rentals-form-sidebar {
  top: calc(var(--site-header-height) + 24px);
}

.field-rentals-form-sidebar-indicator {
  @apply pointer-events-none opacity-0 transition-opacity;
  top: calc(var(--site-header-height) + 24px);

  &.is-active {
    @apply pointer-events-auto opacity-100 duration-500;
  }
}

.field-page-content {
  &.is-loading {
    @apply pointer-events-none opacity-60 blur-sm transition-all;
  }
}

.field-rentals-nav {
  button {
    &:before {
      @apply z-1 absolute top-0 left-0 h-full bg-transparent;
      content: '';
      width: 100%;
      clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
    }
  }

  li {
    &:first-child button:before {
      clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    }

    &:last-child button:before {
      clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  li.tab {
    @screen sm-down {
      &:nth-last-child(2) button:before {
        clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  button.is-active {
    @apply pointer-events-none text-white;

    &:before {
      @apply bg-blue;
    }
  }
}

.field-selector-dropdown-bar {
  .edd-head {
    @apply bg-blue border-none py-3 px-4 text-white md:text-lg lg:py-4 lg:px-6 lg:text-2xl;
  }

  .edd-value {
    @apply text-headline;
  }
}

.field-selector-dropdown-tab {
  @apply h-full md:min-w-[265px] lg:min-w-[295px] xl:min-w-[375px];

  .edd-root:before {
    @apply absolute top-0 left-0 h-full bg-transparent;
    content: '';
    width: 100%;

    @screen md {
      clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }

  .edd-root-has-value {
    &:before {
      @apply bg-blue;
    }

    .edd-head {
      @apply bg-transparent text-white;
    }
  }

  .edd-head {
    @apply text-xxs flex items-center border-none py-3 px-4 md:text-base lg:py-4 lg:px-6 lg:text-lg xl:text-[20px];
  }

  .edd-value {
    @apply text-headline text-center;
  }
}

.field-media-gallery {
  .media-gallery-thumbs .swiper-slide {
    @apply opacity-40 transition-opacity hover:opacity-70;

    &.swiper-slide-thumb-active {
      @apply opacity-100;
    }
  }
}

.rate-groups-table {
  a {
    @apply underline transition-opacity hover:opacity-60;
  }

  @screen sm-down {
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
    }

    td {
      border-bottom: 1px solid #eceff1;
      display: block;
    }

    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
}
