:root {
  --site-announcement-height: 0px;
  --site-header-height: 110px;
  --container-width: 1340px;
  --container-padding: 20px;
  --sm-container-width: 1120px;

  @screen md {
    --container-padding: 6vw;
    --site-header-height: 124px;
  }

  @screen lg {
    --site-header-height: 172px;
  }

  @screen xl {
    --container-padding: 75px;
    --site-header-height: 188px;
  }
}

._preload * {
  transition: none !important;
}

.skip-link {
  @apply absolute overflow-hidden text-sm;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);

  &:focus {
    @apply h-auto w-auto bg-white px-3 py-2 text-black;
    clip: auto;
    z-index: 10000;
  }
}

body {
  margin-top: var(--site-announcement-height);
}

main {
  margin-top: calc(-1 * var(--site-header-height));
  min-height: 80vh;
}

.site-announcement-bar {
  @apply relative;
  margin-top: calc(-1 * var(--site-announcement-height));
}
