/* Page specific styling */

.pagetype-team {
  .page-header {
    @apply md:pb-40 xl:pb-64;
  }

  .element-team {
    @apply relative -mt-32 md:-mt-48 xl:-mt-72;
  }
}

.pagetype-plananeventpage {
  .page-header {
    @apply md:pb-40 xl:pb-64;
  }

  .event-types {
    @apply relative -mt-24 md:-mt-36 xl:-mt-48;
  }
}
