@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

@import 'base/global';
@import 'base/layout';
@import 'base/typography';
@import 'base/components';
@import 'base/page';

@import 'components/button';
@import 'components/datepicker';
@import 'components/dropdown';
@import 'components/form';
@import 'components/mobile-bar';
@import 'components/modal';
@import 'components/page-header';
@import 'components/page-filters';
@import 'components/site-header';
@import 'components/site-footer';
@import 'components/site-widget';
@import 'components/slide-toggle';
@import 'components/slider';
@import 'components/sports-menu';

@import 'elements/faqs';
@import 'elements/large-image-callout';
@import 'elements/media-gallery';
@import 'elements/reviews';
@import 'elements/sports';
@import 'elements/stats';
@import 'elements/timeline';

@import 'sections/fields';
@import 'sections/home';
@import 'sections/league';
@import 'sections/sports';

/* Splash - Can remove once final site is live */
@import 'components/splash-modal';
.splash-header {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

.splash-footer {
  background: linear-gradient(155.07deg, #001f60 19.81%, #000f2f 84.13%);
  clip-path: url(#svg-path-footer);
}

.to-top.is-active {
  @apply pointer-events-auto opacity-100;
}
