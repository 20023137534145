[data-hover-image] {
  img {
    @apply transition-transform duration-300;
  }

  &:hover img {
    transform: scale(1.03);
  }
}

.elevate-scroller {
  &::-webkit-scrollbar {
    @apply bg-gray-light bg-opacity-90;
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-dark;
  }
}
