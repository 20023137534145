.page-header {
  @apply relative;
}

.page-header-default {
  @apply bg-blue-dark text-white;
  padding: var(--site-header-height) 0;
}

.page-header-angle-ne {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);

  @screen md {
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
  }

  @screen lg {
    clip-path: polygon(0 0, 100% 0, 100% 65%, 0 100%);
  }
}

.page-header-image-right {
  @apply bg-blue-dark text-white;

  @screen md {
    --top-offset: 24px;
    padding-top: calc(var(--site-header-height) + var(--top-offset));
    padding-bottom: calc(var(--site-header-height) / 1.5);
  }

  @screen lg {
    --top-offset: 36px;
  }

  @screen xl {
    --top-offset: 48px;
  }

  .page-header-image {
    &:after {
      @apply z-1 absolute inset-0 opacity-30 md:hidden;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 90%);
      content: '';
    }

    @screen md {
      top: calc(var(--site-header-height) + var(--top-offset));
      clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
    }
  }
}

.page-header-content-left {
  @screen md {
    --top-offset: 24px;
    padding-top: calc(var(--site-header-height) + var(--top-offset));
  }

  .page-header-image {
    &:after {
      @apply z-1 absolute inset-0 opacity-40 md:opacity-100;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 90%);
      content: '';
    }
  }

  .page-header-shape {
    transform: skewX(-25deg);

    &:after {
      @apply bg-gray-light absolute right-0 top-0 h-full w-6;
      content: '';
    }
  }
}

.page-header-home {
  @apply mb-40 md:mb-0;
  padding: var(--site-header-height) 0;
}

.page-header-action-photo {
  @apply right-0 -bottom-44 w-[260px] md:bottom-auto md:w-3/5 md:max-w-[540px] lg:-mt-8 lg:max-w-[660px] xl:left-1/2 xl:right-auto xl:-mt-16 xl:w-1/2 xl:max-w-none;

  @screen md {
    top: var(--site-header-height);
  }

  img {
    @apply relative md:-right-16 xl:right-auto xl:max-w-[780px];
  }
}
