.sports-menu-sidebar {
  .sports-menu-trigger[aria-expanded='false'] {
    .for-collapsed {
      @apply block;
    }

    .for-expanded {
      @apply hidden;
    }
  }

  .sports-menu-trigger[aria-expanded='true'] {
    .for-collapsed {
      @apply hidden;
    }

    .for-expanded {
      @apply block;
    }
  }

  .sports-menu {
    @apply z-1 pointer-events-none absolute top-full mt-1 w-full -translate-y-2 transform-gpu rounded bg-white p-4 opacity-0 shadow-md transition-transform duration-300 md:pointer-events-auto md:relative md:top-auto md:m-0 md:translate-y-0 md:rounded-none md:px-0 md:opacity-100 md:shadow-none;

    &.is-active {
      @apply pointer-events-auto translate-y-0 opacity-100;
    }
  }
}
