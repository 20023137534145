@font-face {
  font-family: 'Gretaros';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('@fonts/Gretaros-Regular.woff2') format('woff2'), url('@fonts/Gretaros-Regular.woff') format('woff');
}

address {
  @apply not-italic;
}

strong {
  @apply font-medium;
}

.text-headline {
  @apply font-headline uppercase;
}

.text-primary-hover {
  @apply text-blue-text hover:text-green transition-colors;
}

.h0 {
  @apply text-3xl md:text-4xl lg:text-5xl xl:text-[55px];
}

.h1 {
  @apply text-2xl lg:text-3xl xl:text-4xl;
}

.h3 {
  @apply text-xl lg:text-2xl xl:text-[28px];
}

.h4 {
  @apply text-lg lg:text-xl;
}

.richtext {
  p {
    @apply my-4;
  }

  &.no-first-margin p {
    margin-top: 0;
  }

  &.no-margin p {
    margin: 0;
  }

  &.no-last-margin p:last-child {
    margin-bottom: 0;
  }
}

.wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-headline uppercase;

    &:not(:first-child) {
      @apply mt-[2em] xl:mt-[3em];
    }
  }

  h1,
  h2 {
    @apply text-2xl lg:text-3xl xl:text-4xl;
  }

  h3,
  h4,
  h5,
  h6 {
    @apply text-lg lg:text-2xl;
  }

  p + h2,
  p + h3,
  p + h4,
  h2 + h3 {
    @apply mt-4;
  }

  hr {
    @apply my-8 lg:my-12;
  }

  ul:not([class]) {
    @apply my-8;

    li {
      @apply relative my-4;
      padding-left: 1.25em;

      &:before {
        @apply absolute top-2 left-0 h-2 w-2 bg-center bg-no-repeat xl:top-[10px];
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='5' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.73826 0H7L5.26174 5H0L1.73826 0Z' fill='%238ABB40'/%3E%3C/svg%3E");
      }
    }
  }

  ul.as-checklist-green {
    @apply my-8;

    li {
      @apply relative my-4;
      padding-left: 1.75em;

      &:before {
        @apply absolute top-0.5 left-0 h-5 w-5 bg-center bg-no-repeat;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m2.757 8.39 3.513 4.194L16.65 0 18 1.049 7.73 17H5.135L0 10.708 2.757 8.39Z' fill='%238ABB40'/%3E%3C/svg%3E");
      }
    }

    br {
      @apply hidden;
    }

    strong {
      @apply text-headline block font-normal;
    }
  }

  .as-headline {
    @apply text-headline block;

    strong {
      font-weight: normal;
    }
  }

  a {
    @apply text-blue-text font-medium underline transition-opacity hover:opacity-70;
  }
}

.wysiwyg-article {
  .ss-htmleditorfield-file.image {
    @apply w-full;
  }

  .ss-htmleditorfield-file.image.center,
  img.center,
  img.aligncenter {
    @apply mx-auto max-w-3xl;
  }

  .ss-htmleditorfield-file.image.left,
  img.left,
  img.alignleft {
    @apply md:float-left md:mb-6 md:mr-6 md:w-1/2 xl:mr-10 xl:w-3/5;
  }

  .ss-htmleditorfield-file.image.right,
  img.right,
  img.alignright {
    @apply md:float-right md:mb-6 md:ml-6 md:w-1/2 xl:ml-10 xl:w-3/5;
  }

  .ss-htmleditorfield-file.embed {
    @apply relative;

    width: 100% !important;

    &:after {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .ss-htmleditorfield-file.embed.left {
    @apply md:float-left md:mb-6 md:mr-6 xl:mr-10;

    @screen md {
      width: 50% !important;
    }
  }

  .ss-htmleditorfield-file.embed.right {
    @apply md:float-right md:mb-6 md:ml-6 xl:ml-10;

    @screen md {
      width: 50% !important;
    }
  }

  .captionImage {
    @apply my-8 lg:my-16;
    width: 100% !important;
  }

  .caption {
    @apply text-sm text-gray-500;
  }

  blockquote {
    @apply my-8 text-xl md:text-2xl lg:text-4xl;
  }
}

.wysiwyg-announcement {
  a {
    @apply underline transition-opacity hover:opacity-70;
  }
}

.wysiwyg-splash {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-headline text-2xl uppercase lg:text-3xl xl:text-4xl;
  }

  ul:not([class]) {
    @apply my-8;

    li {
      @apply relative my-4;
      padding-left: 1.75em;

      &:before {
        @apply absolute top-0.5 left-0 h-5 w-5 bg-center bg-no-repeat;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m2.757 8.39 3.513 4.194L16.65 0 18 1.049 7.73 17H5.135L0 10.708 2.757 8.39Z' fill='%238ABB40'/%3E%3C/svg%3E");
      }
    }
  }
}

.splash-header {
  .wysiwyg-splash {
    a {
      @apply hover:text-blue underline;
    }
  }
}
