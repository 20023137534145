/* Clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  display: none;
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@supports (-webkit-overflow-scrolling: touch) {
  input[type='search'] {
    @apply rounded-none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -webkit-border-radius: 0px;
}

.styled-form {
  @apply text-base;

  ::placeholder {
    @apply text-black text-opacity-80;
  }

  input,
  textarea {
    @apply block w-full appearance-none border border-gray-300 bg-white p-4;

    &.has-error {
      @apply border-error;
    }
  }

  textarea {
    @apply resize-none;
  }

  .form-field {
    @apply relative;
  }

  .field.text,
  .field.textarea,
  .field.dropdown {
    .form-field > label,
    .form-field-label {
      @apply sr-only;
    }
  }

  .field.checkboxset li,
  .field.checkbox {
    label {
      @apply relative inline-flex cursor-pointer items-baseline;

      &:hover:before {
        @apply border-gray-400;
      }

      &:before {
        @apply relative mr-3 flex-shrink-0 rounded-sm border border-gray-400;
        content: '';
        width: 1.2em;
        height: 1.2em;
        top: 3px;
      }
    }

    input[type='checkbox'] {
      @apply invisible absolute w-0;

      &:checked ~ label {
        &:before {
          @apply border-gray-400 bg-white;
        }
      }
    }

    &.has-error {
      .form-field-message.bad {
        @apply ml-4;
        padding-left: 14px;
      }
    }
  }

  .field.optionset:not(.checkboxset) {
    & > label {
      @apply sr-only;
    }

    .middleColumn {
      @apply mt-6;
    }

    .optionset {
      @apply flex space-x-6;

      li {
        label {
          @apply relative inline-flex cursor-pointer items-start font-medium text-white opacity-60 transition-opacity hover:opacity-100;

          &:before {
            @apply relative -top-0.5 mr-3 flex-shrink-0 rounded-full border-[3px] border-white transition-all;
            content: '';
            width: 1.75em;
            height: 1.75em;
          }
        }

        input[type='radio'] {
          @apply invisible absolute w-0;

          &:checked ~ label {
            @apply opacity-100;

            &:after {
              content: '';
              @apply absolute top-[5px] left-[7px] h-[14px] w-[14px] rounded-full bg-white lg:h-[17.5px] lg:w-[17.5px];
            }
          }
        }
      }
    }
  }

  .field.checkboxset.large {
    li {
      @apply md:my-4;
    }
  }

  .field.file {
    position: relative;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }

    &.has-error {
      .file-input-placeholder {
        @apply border-error;
      }
    }
  }

  .field.date-field {
    @apply relative;

    &:before {
      @apply z-1 pointer-events-none absolute top-[19px] right-5 h-5 w-5 bg-cover opacity-70;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 11h2v2H7v-2m14-6v14c0 1.11-.89 2-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h1V1h2v2h8V1h2v2h1a2 2 0 0 1 2 2M5 7h14V5H5v2m14 12V9H5v10h14m-4-6v-2h2v2h-2m-4 0v-2h2v2h-2m-4 2h2v2H7v-2m8 2v-2h2v2h-2m-4 0v-2h2v2h-2Z'/%3E%3C/svg%3E");
    }

    input[type='text'] {
      @apply cursor-pointer;
    }
  }

  .form-actions [type='submit'] {
    &:disabled {
      @apply cursor-not-allowed opacity-25;
    }
  }
}

.form-field-message,
.message.required {
  @apply text-error mt-2 inline-block text-xs font-medium;

  .field.checkbox & {
    @apply block;
  }

  .field.optionset & {
    @apply px-0 md:block;
  }
}

#SplashSubscribeForm_SplashSubscribeForm {
  @apply py-4 md:min-w-[600px];

  #SplashSubscribeForm_SplashSubscribeForm_Email_Holder {
    label {
      @apply sr-only;
    }
  }
}

#SubscribeForm_SubscribeForm {
  .form-field {
    @apply relative;
  }

  .form-actions {
    @apply right-0 top-0 bottom-0 mt-2 text-center md:absolute md:mt-0 md:w-48;
  }

  [type='email'] {
    @apply py-3 text-black outline-none md:pr-28;

    @screen md {
      width: calc(100% - 96px);
    }
  }

  [type='submit'] {
    @apply w-full items-center !opacity-100 md:flex md:h-[50px] md:border-transparent;

    @screen md {
      width: calc(100% - 12px);
    }
  }

  #SubscribeForm_SubscribeForm_Email_Holder {
    label {
      @apply sr-only;
    }
  }
}
