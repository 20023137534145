.swiper-pagination-bullets {
  @apply flex justify-center space-x-2;
}

.swiper-pagination-bullet {
  @apply bg-gray-dark block h-2 w-2 cursor-pointer hover:bg-black;
  transform: skewX(-20deg);
}

.swiper-pagination-bullet-active {
  @apply bg-black;
}

.swiper-button-disabled {
  @apply pointer-events-none opacity-30;
}
