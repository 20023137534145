.element-largeimagecallout {
  .element-screen {
    @apply sm-down:bg-black sm-down:bg-opacity-50 absolute inset-0;

    @screen md {
      background-image: linear-gradient(to right, transparent 30%, rgba(0, 0, 0, 0.7));
    }
  }

  .element-image img {
    transform: scale(1.4);

    @screen lg {
      transform: scale(1.2);
    }
  }
}
