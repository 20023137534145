.reviews-shape {
  @apply absolute top-0 bottom-0 left-0 hidden md:block xl:left-32;
  background: linear-gradient(155.07deg, #001f60 19.81%, #000f2f 84.13%);
  transform: skewX(-20deg);
  width: 100vw;
}

.reviews-slider .slider-pagination.as-light {
  .swiper-pagination-bullet {
    @apply md:bg-white md:bg-opacity-50 md:hover:bg-opacity-100;
  }

  .swiper-pagination-bullet-active {
    @apply md:bg-white;
  }
}
