.subsite-elevate {
  &.tingle-enabled {
    overflow: hidden;
  }

  .tingle-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    visibility: hidden;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: rgba(0, 31, 96, 0.9);
    opacity: 0;
    user-select: none;
    cursor: pointer;
    transition: opacity 0.2s ease;
    display: flex;
    width: 100%;

    @screen md {
      display: flex;
    }

    &--noOverlayClose {
      cursor: default;
    }

    &--visible {
      visibility: visible;
      opacity: 1;

      .tingle-modal-box {
        transform: scale(1);
      }
    }

    &--overflow {
      overflow-y: scroll;
      padding-top: 75px;
      padding-bottom: 75px;
    }

    &__close {
      @apply bg-transparent text-white transition-opacity;
      position: absolute;
      top: -48px;
      right: 10px;
      z-index: 1000;
      padding: 0;
      line-height: 1;
      cursor: pointer;

      &:hover:not(:disabled),
      &:focus:not(:disabled),
      &:active:not(:disabled) {
        @apply opacity-70;
      }
    }

    &__closeIcon {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;

      svg {
        width: 28px;
        margin-left: 8px;
      }
    }

    &__closeLabel {
      display: none;
    }

    &.modal--ajax {
      @apply invisible;

      .tingle-modal__close {
        display: none;
      }
    }
  }

  .tingle-modal-box {
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    min-height: 1px;
    width: 100%;
    opacity: 1;
    cursor: auto;
    transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(0.8);
    padding: 0 10px;

    @screen md {
      width: auto;
    }
  }

  .modal {
    &--loading {
      &:before {
        content: 'Loading...';
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        display: block;
      }
    }

    &--video {
      .tingle-modal-box {
        min-width: 75%;

        @screen xl {
          min-width: 60%;
        }
      }

      .tingle-modal-box__content {
        position: relative;

        &:after {
          content: '';
          display: block;
          height: 0;
          width: 100%;
          padding-top: 57.866667%;
        }
      }

      &.is-youtube .tingle-modal-box__content:after {
        padding-top: 56.25%;
      }

      video,
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &--embed {
      .tingle-modal-box {
        min-width: 75%;

        @screen xl {
          min-width: 60%;
        }
      }

      .tingle-modal-box__content {
        position: relative;

        &:after {
          content: '';
          display: block;
          height: 0;
          width: 100%;
          padding-top: 125%;
        }
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  [data-auto-modal] {
    display: none;
  }
}
