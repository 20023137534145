.element-mediagallery {
  .media-gallery-thumbs .swiper-slide {
    @apply opacity-40 transition-opacity hover:opacity-70;

    &.swiper-slide-thumb-active {
      @apply opacity-100;
    }
  }

  .media-gallery-content p {
    @apply lg:text-xl xl:text-2xl;
  }
}
