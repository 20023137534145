.element-sports {
  .sport-block {
  }

  .sport-block-inner {
    clip-path: polygon(0 30%, 100% 80%, 100% 100%, 0% 100%);
  }

  .sport-block-shape {
    transform: skewY(20deg);
  }
}
