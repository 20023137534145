.js-site-menu-open {
  @apply overflow-hidden;
}

.site-header {
  @apply sticky -top-[1px] z-30 bg-transparent py-6 text-white lg:py-8;
  height: var(--site-header-height);

  &.is-stuck {
    @apply bg-blue-dark;
  }
}

.site-menu {
  @apply bg-blue-dark md-down:overflow-scroll-touch md-down:overflow-y-auto fixed top-0 right-0 z-40 h-screen w-full translate-x-full transform-gpu px-6 pt-12 pb-48 transition-transform duration-300 md:max-w-[380px] lg:relative lg:h-auto lg:w-auto lg:max-w-none lg:translate-x-0 lg:bg-transparent lg:p-0 lg:transition-none;

  .js-site-menu-open & {
    @apply translate-x-0 transform-gpu shadow;
  }
}

.site-menu-primary {
  @apply space-y-4 lg:flex lg:space-y-0;

  a {
    @apply text-xl lg:text-base xl:text-lg;
  }

  & > li.section:not(:hover) > span > a {
    @apply relative;

    &:after {
      @apply bg-blue absolute left-0 top-full mt-0.5 hidden h-0.5 w-full lg:block;
      content: '';
    }
  }
}

.site-menu-item {
  @apply lg:p-2 xl:px-4;

  &.has-children {
    &:hover .site-menu-submenu {
      @apply lg:pointer-events-auto lg:translate-y-0 lg:opacity-100;
    }
  }
}

.site-menu-submenu {
  @apply lg:text-blue-text top-full hidden space-y-2 pt-2 lg:pointer-events-none lg:absolute lg:left-1/2 lg:-ml-2 lg:block lg:w-fit lg:min-w-[120px] lg:-translate-x-1/2 lg:-translate-y-2 lg:rounded lg:bg-white lg:py-3 lg:px-5 lg:text-center lg:opacity-0 lg:shadow-md lg:transition-all lg:duration-300;

  &:before {
    @apply absolute -top-5 left-1/2 hidden -translate-x-1/2 lg:block;
    content: '';
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white;
    opacity: 1;
    transition: 0.2s ease;
    pointer-events: none;
  }

  .site-menu-item {
    @apply lg:m-0 lg:whitespace-nowrap lg:p-0 lg:font-medium;
  }
}

.site-menu-primary {
  & > li li {
    @apply -ml-3;
  }
}

.site-menu-screen {
  @apply pointer-events-none fixed inset-0 z-20 opacity-0 transition-opacity;

  &:before {
    @apply absolute inset-0 z-20 bg-white bg-opacity-30;
    content: '';
  }

  .js-site-menu-open & {
    @apply pointer-events-auto opacity-100;
  }
}
