.btn {
  @apply inline-flex justify-center border px-6 py-[10px] font-medium transition-colors xl:px-10;
  transform: translateZ(0) skewX(-15deg);

  span {
    transform: translateZ(0) skewX(15deg);
  }
}

.editor-btn {
    @apply inline-flex justify-center border px-6 py-[10px] font-medium transition-colors xl:px-10;
    clip-path: polygon(11% 0%, 100% 0%, 89% 100%, 0% 100%);
    transform: none !important;
}

.btn-primary {
  @apply bg-green hover:bg-blue border-green hover:border-blue hover:text-white;
  color: #000 !important;
  text-decoration: none !important;
}

.btn-hollow-white {
  @apply hover:bg-blue hover:border-blue border border-white bg-transparent text-white;
}

.btn-hollow-black {
  @apply hover:bg-blue hover:border-blue border border-black bg-transparent text-black hover:text-white;
}

.btn-no-angle {
  @screen sm-down {
    transform: translateZ(0) skewX(0);

    span {
      transform: translateZ(0) skewX(0);
    }
  }
}

.btn-full {
  @apply flex w-full;
}

.splash-btn {
  @apply bg-green hover:bg-blue inline-flex justify-center px-12 py-3 font-medium transition-colors hover:text-white;
  transform: skewX(-15deg);

  span {
    transform: skewX(15deg);
  }
}
