.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(var(--container-width) + (2 * var(--container-padding)));
}

.sm-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(var(--sm-container-width) + (2 * var(--container-padding)));
}
