.pagetype-homepage {
  .element-media,
  .element-mediagallery {
    @apply z-1 relative;
  }

  .element-mediagallery + .element-stats,
  .element-media + .element-stats {
    @apply relative;

    &:before {
      @apply pointer-events-none absolute -bottom-12 right-0 hidden w-full bg-contain bg-right bg-no-repeat md:block;
      content: '';
      background-image: url(@images/home-shape.svg);
      height: min(50vw, 780px);
    }
  }
}
