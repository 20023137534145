select.js-dropdown {
  @apply invisible h-[58px];
}

.edd-root {
  display: block;
  position: relative;
  min-width: 120px;
  width: 100%;
  height: 100%;
  max-width: 100%;
  user-select: none;
}

.edd-root-disabled {
  color: #7b868c;
  cursor: not-allowed;
}

.edd-head {
  @apply relative h-full w-full overflow-hidden border border-gray-300 bg-white p-4;
}

.has-error .edd-head {
  @apply border-error;
}

.edd-root:not(.edd-root-disabled):not(.edd-root-open) .edd-head:hover {
  @apply cursor-pointer;
}

.edd-value {
  width: calc(100% - 24px);
  display: block;
  vertical-align: middle;
  text-align: left;
}

.edd-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 5px);
  right: 24px;
  transition: transform 150ms;
  cursor: pointer;
}

.edd-arrow::before {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  border-right: 1.5px solid currentColor;
  border-bottom: 1.5px solid currentColor;
  top: 0;
  right: 0;
  transform: rotate(45deg);
  transform-origin: 50% 25%;
}

.edd-root-open .edd-arrow {
  transform: rotate(180deg);
}

.edd-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edd-root:not(.edd-root-disabled) .edd-value,
.edd-option {
  cursor: pointer;
}

.edd-select {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: -100%;
  top: 0;
}

.edd-root-native .edd-select {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.is-dropdown-ios-tablet {
  .edd-body {
    visibility: hidden;
  }

  .edd-root-open .edd-arrow {
    transform: none;
  }

  .edd-select {
    left: 0;
  }
}

.edd-body {
  @apply bg-white py-2;
  margin: 8px 0;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  transition: opacity 200ms;
}

.edd-root-open-above .edd-body {
  top: 100%;
}

.edd-root-open-below .edd-body {
  top: 100%;
}

.edd-items-list {
  overflow: auto;
  max-height: 0;
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-overflow-scrolling: touch;
}

.edd-option {
  @apply px-4 py-1 text-left text-black;
}

.edd-option-selected {
  @apply font-medium;
}

.edd-option-focused:not(.edd-option-disabled) {
  @apply text-green;
}

.edd-option-disabled,
.edd-group-disabled .edd-option {
  cursor: default;
  color: #999;
}

.dropdown-as-ui {
  .edd-head {
    @apply py-3;
  }
}

.dropdown-as-blue {
  .edd-head {
    @apply bg-blue border-blue text-white;
  }
}
