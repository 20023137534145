.sports-grid {
  .sport-leagues-trigger[aria-expanded='false'] {
    .for-collapsed {
      @apply block;
    }

    .for-expanded {
      @apply hidden;
    }
  }

  .sport-leagues-trigger[aria-expanded='true'] {
    .for-collapsed {
      @apply hidden;
    }

    .for-expanded {
      @apply block;
    }
  }

  .sport-leagues-menu {
    &.is-active {
      @apply pointer-events-auto translate-y-0 opacity-100;
    }
  }
}
