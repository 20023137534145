.element-stats {
  .stats-container {
    @screen md {
      transform: skewX(-20deg);
    }

    &:before {
      @apply absolute right-[99%] top-0 hidden h-full w-full md:block;
      content: '';
    }
  }

  &.as-blue .stats-container:before {
    @apply bg-blue;
  }

  &.as-green .stats-container:before {
    @apply bg-green;
  }

  .stat-text {
    @screen md {
      transform: skewX(20deg);
    }
  }
}
